import React, { ReactNode, useEffect, useState } from 'react';
import { Tabs, Tab, Box, Typography, Drawer, IconButton, Button, Select, MenuItem, ThemeProvider, createTheme, InputLabel, FormControl, TextField, InputAdornment } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, ArrowBack, Refresh, Search, ArrowDownward, ArrowUpward, ExitToApp } from '@mui/icons-material';
import { ParentSize } from '@visx/responsive';
import './styles.css';
import { StatPlayType } from '../../helper';

interface PageContainerProps {
  children: ReactNode;
  timeframe: string;
  setTimeframe: any;
  activeTab?: number;
  setActiveTab?: any;
  onTeamPage?:boolean;
  difficulty: number,
  setDifficulty: any,
  sortDirection?: string;
  handleSortDirectionChange?: any;
  sortField?: any;
  searchTerm?: any;
  handleSearchChange?:any;
  handleSortChange?:any
  disabledTimeframe?:any
  setDisabledTimeframe?:any
}

const PageContainer: React.FC<PageContainerProps> = ({ 
  onTeamPage, 
  children, 
  timeframe, 
  setTimeframe, 
  difficulty,
  setDifficulty,
  activeTab, 
  setActiveTab,
  sortDirection,
  handleSortDirectionChange,
  sortField,
  searchTerm,
  handleSearchChange,
  handleSortChange,
  disabledTimeframe,
  setDisabledTimeframe
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);  
  const statPlayTypes = Object.values(StatPlayType);

  useEffect(() => {
    if(window.location.href?.includes('team')) {
      setActiveTab(1)
    }
  }, [])

  const handleChange = (event: any, newValue: any) => {
    if(newValue === activeTab && !window.location.href?.includes('player')) {
      return
    }
    if(newValue === 0) {
      navigate("/")
    }else if(newValue === 1) {
      navigate(`/teams`)
    }
    setActiveTab(newValue);
    if(isDrawerOpen) {
      setIsDrawerOpen(false)
    }
  };

  const tabLabels = ['Main Stats', 'Team Summary'];

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const difficulties = [
    {key: 'Youth', value: 0},
    {key: 'High School', value: 1},
    {key: 'College', value: 2},
    {key: 'Pro', value: 3},
  ]

  const isFromTeam = JSON.parse(window.localStorage.getItem('navigatedFromTeam') ?? '{}');


  useEffect(() => {
    if(location.pathname?.includes('player') && timeframe === StatPlayType.NO_CSS) {
      setTimeframe(StatPlayType.ALL_TIME)
    } else if (disabledTimeframe) {
      setDisabledTimeframe(false)
    }
  }, [location.pathname?.includes('player') ])

  
                    
  const logout = () => {
    window.localStorage.removeItem('user');
    navigate('/login')
  }

  return (
    <ParentSize>
      {({ width }) => {
        const isMobile = width < 1000;

        return (
          <div>
            <div className="top_page_banner">
              {isMobile && (
                <IconButton onClick={handleDrawerToggle} style={{ color: 'white', marginLeft: 10 }}>
                  <Menu />
                </IconButton>
              )}
              <img
                onClick={() => !window.location.href?.includes('login') ? navigate('/') : undefined}
                src={`${process.env.PUBLIC_URL}/logo_white.svg`}
                alt="Logo"
                className="main_logo"
              />
              <ThemeProvider theme={darkTheme}>
              <div style={{display: 'flex', gap: isMobile ? '5px' : '10px', left: isMobile ? '0px' : '-20px', position: 'relative'}}>
             
               {(location.pathname === '/' || location.pathname?.includes('player')) ? 
               <div style={{position: 'relative', top: isMobile ? '8px' : '0px'}}>
               <ThemeProvider theme={darkTheme}>
                <div style={{display: 'flex', gap: '5px'}}>
               <FormControl >
                  <InputLabel style={{fontSize: isMobile ? '13px' : undefined}} id="demo-simple-select-label">Difficulty</InputLabel>
                  <Select style={{height: isMobile ? '30px' : '36.5px',width: isMobile ? '95px' : undefined, fontSize:   isMobile ? '13px' : undefined}} labelId='demo-simple-select-label' label='Time Frame' size="small" value={difficulty} onChange={(e:any) => {
                    setDifficulty(e.target.value);
                    window.localStorage.setItem('difficulty', e.target.value);
                  }}>
                 {difficulties.map((type) => (
                  <MenuItem key={type.key} value={type.value}>
                    {type.key}
                  </MenuItem>
                ))}
                </Select>
                </FormControl>
              {!disabledTimeframe &&  <FormControl >
                  <InputLabel style={{fontSize: isMobile ? '13px' : undefined}} id="demo-simple-select-label">Time Frame</InputLabel>
                  <Select style={{height: isMobile ? '30px' : '36.5px',width: isMobile ? '95px' : undefined, fontSize:   isMobile ? '13px' : undefined}} labelId='demo-simple-select-label' label='Time Frame' size="small" value={timeframe} onChange={(e:any) => {
                    setTimeframe(e.target.value);
                    window.localStorage.setItem('timeframe', e.target.value);
                  }}>
                 {statPlayTypes?.filter((e:StatPlayType) => {
                  if(location.pathname?.includes('player') ) {
                    return e !== StatPlayType.NO_CSS
                  } else {
                    return true
                  }
                 }).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
                </Select>
                </FormControl>}
                </div>
                </ThemeProvider>
                </div> : null}
              {location.pathname?.includes('player') ? (
                 isMobile ? <IconButton
                  style={{ top: '2px', }}
                  color="info"
                  onClick={() => {
                    if(onTeamPage && isFromTeam?.teamID) {
                      navigate(`/teams`)
                    } else {
                      navigate('/')
                    }
                  }}
                >
                  <ArrowBack style={{color: 'white'}}/>
                </IconButton>
                : 
                <Button
                  className="back-button"
                  variant="contained"
                  color="info"
                  onClick={() => {
                    if(onTeamPage && isFromTeam?.teamID) {
                      navigate(`/teams`)
                    } else {
                      navigate('/')
                    }
                  }}
                  startIcon={<ArrowBack style={{ position: 'relative', top: '-2px' }} />}
                >
                 {onTeamPage && isFromTeam?.teamID ? `Back to ${isFromTeam?.teamName}`: ' Back To All Players'}
                </Button>

              ) : location.pathname === '/' ? (isMobile ? (
                <IconButton
                  style={{ top: '2px',}}
                  color="info"
                  onClick={() => window.location.reload()}
                >
                  <Refresh />
                </IconButton>
              ) : (
                <Button
                  className="back-button"
                  variant="contained"
                  color="info"
                  onClick={() => window.location.reload()}
                  startIcon={<Refresh style={{ position: 'relative', top: '-2px' }} />}
                >
                  {width < 1000 ? '' : 'Refresh'}
                </Button>
              )) : location?.pathname?.includes('teams') ? (
                  <div style={{ marginRight: '10px', display: 'flex', gap: '10px' }}>
                  <TextField
                    variant="outlined"
                    style={{ width: isMobile ? '100px' : undefined, padding: isMobile ? '0px' : undefined }}
                    size={isMobile ? 'small' : 'medium'}
                    sx={{
                      padding: isMobile ? '0px' : undefined,
                      '& .MuiInputBase-root': {
                        paddingLeft: isMobile ? '5px' : undefined,
                      }
                    }}
                    placeholder={isMobile ? "Search" : "Search Players"}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search fontSize={isMobile ? 'small' : undefined} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ display: 'flex' }}>
                    <FormControl size={isMobile ? 'small' : 'medium'}>
                      <InputLabel id="sort-by">Sort By</InputLabel>
                      <Select
                        size={isMobile ? 'small' : 'medium'}
                        label="Sort By"
                        labelId="sort-by"
                        value={sortField}
                        onChange={handleSortChange}
                        displayEmpty
                        variant="outlined"
                      >
                        <MenuItem value="" disabled>
                          Sort by
                        </MenuItem>
                        <MenuItem value="playerName">Name</MenuItem>
                        <MenuItem value="playerNumber">Number</MenuItem>
                        <MenuItem value="playerPosition">Position</MenuItem>
                        <MenuItem value="css">CSS</MenuItem>
                        <MenuItem value="lastLogin">{isMobile ? 'Active' : 'Last Played'}</MenuItem>
                        <MenuItem value="int">INT</MenuItem>
                        <MenuItem value="gamesPlayed"># Played</MenuItem>
                        <MenuItem value="gamesQuit"># Quit</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                    className="sort_button"
                      sx={{
                        minWidth: '0px',
                        padding: '0px', 
                        borderColor: 'rgba(255,255,255,.25)', 
                        color: 'white', 
                        '&:hover': {
                          borderColor: 'white',
                          backgroundColor: 'rgba(255, 255, 255, 0.08)'
                        }
                      }}
                      variant='outlined'
                      onClick={handleSortDirectionChange}
                    >
                      {sortDirection === 'asc' ? (
                        <ArrowDownward fontSize={isMobile ? 'small' : 'medium'} />
                      ) : (
                        <ArrowUpward fontSize={isMobile ? 'small' : 'medium'} />
                      )}
                    </Button>
                  </div>
                </div>
              ) : null}
              </div>
            </ThemeProvider>
            </div>
            <div className="table_padding">
              <div className="main_page_container">
                {(!isMobile && !window.location.href?.includes('login')) && (
                  <div className="side_panel">
                    <Tabs
                      orientation="vertical"
                      value={activeTab}
                      onChange={handleChange}
                      TabIndicatorProps={{ style: { display: 'none' } }}
                      className="tabs_selector"
                      sx={{
                        '& .MuiTab-root': {
                          color: 'white',
                          fontSize: '24px',
                          textTransform: 'none',
                        },
                        '& .Mui-selected': {
                          backgroundColor: 'red',
                          color: 'white',
                        },
                        '& .Mui-disabled': {
                          color: 'grey',
                        },
                      }}
                    >
                      {tabLabels.map((label, index) => (
                        <Tab key={index} label={label} disabled={index > 1} onClick={() => {
                          if(index === 0 && location.pathname?.includes('player')) {
                            navigate('/')
                          } else if (index === 1 && location.pathname?.includes('player')) {
                            navigate(`/teams`)
                          }
                        }}/>
                      ))}
                    </Tabs>
                    <ThemeProvider theme={darkTheme}>
                      <Button endIcon={<ExitToApp/>} variant='outlined' onClick={logout}>Logout</Button>
                    </ThemeProvider>
                  </div>
                )}
                {children}
              </div>
            </div>
            <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerToggle}>
              <Box
                sx={{
                  width: 280,
                  backgroundColor: '#1A283A',
                  height: '100%',
                  padding: '20px 0',
                }}
                role="presentation"
              >
                <Tabs
                  orientation="vertical"
                  value={activeTab}
                  onChange={handleChange}
                  className='drawer-tabs tabs_selector'
                  TabIndicatorProps={{ style: { display: 'none' } }}
                  sx={{
                    '& .MuiTab-root': {
                      color: 'white',
                      fontSize: '24px',
                      textTransform: 'none',
                    },
                    '& .Mui-selected': {
                      backgroundColor: 'red',
                      color: 'white',
                    },
                    '& .Mui-disabled': {
                      color: 'grey',
                    },
                  }}
                >
                  {tabLabels.map((label, index) => (
                    <Tab key={index} label={label} disabled={index > 1} />
                  ))}
                </Tabs>
              </Box>
            </Drawer>
          </div>
        );
      }}
    </ParentSize>
  );
};

export default PageContainer;
