import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  fromPromise,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context'; // Corrected path
import { onError } from '@apollo/client/link/error'; // Corrected path

// Define the refreshAccessToken mutation
const REFRESH_TOKEN_MUTATION = gql`
  mutation refreshAccessToken {
    adminUserTokenRefresh {
      status
      message
      accessToken
      challengeType
      session
      expiresAt
    }
  }
`;

// Get stored tokens from localStorage
const getUserToken = () => {
  const user = JSON.parse(window.localStorage.getItem('user') || '{}');
  return user?.accessToken || null;
};

const getRefreshToken = () => {
  const user = JSON.parse(window.localStorage.getItem('user') || '{}');
  return user?.refreshToken || null;
};

// Function to remove user from local storage and redirect to login
const logoutUser = () => {
  window.localStorage.removeItem('user');
  window.location.href = '/login';
};

// Function to save new tokens in localStorage
const saveNewTokens = (accessToken: string, refreshToken: string) => {
  const user = JSON.parse(window.localStorage.getItem('user') || '{}');
  window.localStorage.setItem(
    'user',
    JSON.stringify({ ...user, accessToken, refreshToken })
  );
};

// Function to refresh the access token
const refreshToken = async (client: ApolloClient<any>) => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    console.error('No refresh token available, logging out');
    logoutUser(); // Log out if there is no refresh token
    return null;
  }

  try {
    const { data } = await client.mutate({
      mutation: REFRESH_TOKEN_MUTATION,
      context: {
        headers: {
          Authorization: `Bearer ${refreshToken}`, // Include the refresh token in the header
        },
      },
    });

    if (data?.adminUserTokenRefresh?.accessToken) {
      const newAccessToken = data.adminUserTokenRefresh.accessToken;

      // Save new tokens (accessToken and refreshToken)
      saveNewTokens(newAccessToken, refreshToken);
      return newAccessToken;
    } else {
      throw new Error('Failed to refresh token');
    }
  } catch (error) {
    console.error('Failed to refresh token:', error);
    logoutUser(); // Log out user if refresh fails
    return null;
  }
};

// Create the HttpLink for API calls
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include',
});

// Error handling and token refreshing
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions?.code === 'UNAUTHENTICATED' && err.message !==  'Invalid Refresh Token') {
        // Try to refresh the token
        return fromPromise(
          refreshToken(client)
            .then((newAccessToken) => {
              if (newAccessToken) {
                // Modify the operation to include the new token
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${newAccessToken}`,
                  },
                }));
                return forward(operation);
              }
              return;
            })
            .catch((err) => {
              console.error('Refresh token error:', err);
              return;
            })
        ).flatMap((response) => response || forward(operation));
      }
    }
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

// Set the Authorization header with the access token
const authLink = setContext(async (operation, { headers }) => {
  const token = getUserToken();

  // If the operation is refreshAccessToken, skip adding the access token
  if (operation.operationName === 'refreshAccessToken') {
    return {
      headers: {
        ...headers,
      },
    };
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Apollo Client setup
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authLink, errorLink, httpLink]), 
});

export default client;
